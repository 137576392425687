<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="导入入库单"
    :visible.sync="show"
    width="600px"
  >
    <div class="content">
      <div>
        <el-form
          label-width="120px"
          ref="form"
          :model="params"
          v-loading="loading"
        >
          <el-form-item label="入库类型:">
            <el-select v-model="params.invoiceMethod" clearable>
              <el-option value="001" label="采购入库"></el-option>
              <el-option value="002" label="礼品入库"></el-option>
              <el-option value="003" label="其他入库"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入库仓库">
            <el-select v-model="params.groupId" clearable>
              <el-option
                v-for="(itme, index) in groupList"
                :key="index"
                :value="itme.value"
                :label="itme.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="进货单号">
            <el-input v-model="params.invoiceOrder" />
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="params.remark" />
          </el-form-item>
          <el-form-item label="请选择文件">
            <el-button type="text" @click="getDownloadTemplateMchUrl"
              >（下载模板）</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="tip">
        请上传商品批量导入模板
        
      </div> -->
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>

    <el-upload
      drag
      style="margin-left: 120px"
      ref="upload"
      :on-change="onchange"
      :show-file-list="true"
      :multiple="false"
      :auto-upload="false"
      accept=".csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/x-zip,application/x-zip-compressed, application/vnd.ms-excel,.xls,.doc,.docx,application/msword,"
      name="file"
      action="null"
    >
      <div @click="clearHandler">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </div>
    </el-upload>

    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      title="错误信息"
      :visible.sync="errorVisible"
      width="400px"
    >
      <div class="content" style="max-height: 300px; overflow: auto">
        <p v-for="item in failList" :key="item.id">
          <b>第{{ item.index }}行</b> {{ item.errorMsg }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="errorVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { BASE_URL } from '@/constant'

export default {
  mixins: [dialogCommonParams],
  props: ['groupList'],
  data() {
    return {
      file: null,
      loading: false,
      errorVisible: false,
      failList: [],
      params: {
        invoiceMethod: '',
        groupId: '',
        invoiceOrder: '',
        remark: ''
      }
    }
  },

  methods: {
    onchange(file) {
      this.file = file.raw
    },

    clearHandler() {
      this.$refs.upload.clearFiles()
    },

    async submit() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('groupId', this.params.groupId)
        formData.append('invoiceOrder', this.params.invoiceOrder)
        formData.append('remark', this.params.remark)
        formData.append('invoiceType', 1)
        formData.append('invoiceMethod', this.params.invoiceMethod)
        const res = await this.$api.commodity.commodityImport(formData)
        if (res.data) {
          const errLength = res.data.length
          if (errLength) {
            this.errorVisible = true
            this.failList = res.data
            this.failList.map(itme => {
              itme.index = Number(itme.index) + 1
            })
          } else {
            this.show = false
          }
        } else {
          this.show = false
        }
        // if (errLength) {
        //   this.errorVisible = true
        //   this.failList = res.data
        //   this.failList.map(itme => {
        //     itme.index = Number(itme.index) + 1
        //   })
        // } else {
        //   this.show = false
        // }

        this.$emit('getData')

        this.$message({
          message:
            errLength == 0
              ? '导入成功'
              : `部分导入成功，其中有${errLength}条导入失败`,
          type: 'success',
          duration: errLength == 0 ? 3000 : 5000
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async getDownloadTemplateMchUrl() {
      try {
        this.loading = true
        window.open(
          BASE_URL +
            '/downLoad/template/goodsInvoiceTemplate.xlsx?token=' +
            localStorage.getItem('token'),
          'target'
        )
      } catch (error) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
  p {
    margin: 6px;
  }
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}
</style>
