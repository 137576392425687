<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="详情"
    :visible.sync="show"
    width="900px"
    :loading="loading"
  >
    <div class="content">
      <div class="con-div">
        <span>入库类型：</span>
        <span>{{ params.invoiceMethodDesc }}</span>
        <span class="spans">入库仓库：</span>
        <span>{{ params.groupName }}</span>
        <span class="spans">进货单号：</span>
        <span>{{ params.invoiceOrder }}</span>
        <span class="spans">入库时间：</span>
        <span>{{ params.createTime }}</span>
      </div>
      <div style="margin-top: 10px">
        备注： <span>{{ params.remark }}</span>
      </div>
      <div class="con-div">
        <span class="span1">入库商品清单：</span>
        <span> {{ params.totalGoods }} 种商品</span>
        <span class="spans">合计（元）：</span>
        <span> {{ params.totalPrice }}</span>
      </div>
    </div>
    <el-table :data="params.details" border height="100%" style="width: 100%">
      <el-table-column
        show-overflow-tooltip
        prop="goodsName"
        label="商品名称"
        width="130"
      />
      <el-table-column
        show-overflow-tooltip
        prop="barNo"
        width="130"
        label="条形码"
      />
      <el-table-column
        show-overflow-tooltip
        prop="specification"
        width="130"
        label="规格"
      />
      <el-table-column
        show-overflow-tooltip
        prop="goodsBrand"
        width="130"
        label="品牌"
      />
      <el-table-column
        show-overflow-tooltip
        prop="goodsCount"
        width="130"
        label="入库数量"
      />
      <el-table-column
        show-overflow-tooltip
        prop="price"
        width="130"
        label="进货单价（元）"
      />
      <el-table-column
        show-overflow-tooltip
        prop="total"
        width="130"
        label="入库小计（元）"
      />
      <el-table-column
        show-overflow-tooltip
        prop="sellPrice"
        width="130"
        label="当前零售价（元）"
      />
      <el-table-column
        show-overflow-tooltip
        prop="productionDate"
        width="130"
        label="生产日期"
      />
      <el-table-column
        show-overflow-tooltip
        prop="timeLimit"
        width="130"
        label="保质期（天）"
      />
    </el-table>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="show = false">取消</el-button> -->
      <el-button type="primary" @click="putExel" :loading="loading"
        >下载入库单</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { BASE_URL } from '@/constant'

export default {
  mixins: [dialogCommonParams],
  props: ['invoiceNo'],
  data() {
    return {
      file: null,
      loading: false,
      errorVisible: false,
      params: {}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const res = await this.$api.commodity.getVehicleList(this.invoiceNo)
        console.log(res.data)
        this.params = res.data
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },

    async putExel() {
      try {
        this.loading = true
        await this.$api.commodity.exportExel(this.invoiceNo)
      } catch (error) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
  p {
    margin: 6px;
  }
  .con-div {
    margin-top: 10px;
    .spans {
      margin-left: 30px;
    }
    .span1 {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}
</style>
